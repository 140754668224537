<template>
    <div class="result">
        <div class="result-top">
            <div class="result-top-position">
                <img src="@/assets/chenggong.png" alt />
                <p>订单支付成功！</p>
            </div>
        </div>
        <div class="result-order" v-if="false">
            <div>
                <p>预约到店</p>
                <p>请您提前预约,预约不等于留位</p>
            </div>
            <div>
                <router-link to tag="p">立即预约</router-link>
            </div>
        </div>
        <!-- 公众号二维码 -->
        <we-chat-public-q-r-code></we-chat-public-q-r-code>
        <div class="result-button">
            <router-link class="red" to="/allorder?cur=0" replace tag="p">查看订单</router-link>
            <router-link to="/home" replace tag="p">返回首页</router-link>
        </div>
    </div>
</template>
<script>
import WeChatPublicQRCode from "@/components/WeChatPublicQRCode";
export default {
    components: {
        WeChatPublicQRCode
    },
    data() {
        return {};
    },
    created() {}
};
</script>


<style lang="scss" scoped>
.result {
    width: 100%;
    height: 100vh;
    background: #f6f6f6;

    .details-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.3rem;
        box-sizing: border-box;
        font-size: 0.38rem;
        background: #f4f4f4;
    }

    .result-top {
        width: 100%;
        height: 4.65rem;
        background: #fff;
        margin-bottom: 0.24rem;

        .result-top-position {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 4.65rem;
            background: #fff;

            img {
                width: 2.88rem;
                // height: 1.93rem;
            }

            p {
                font-size: 0.28rem;
                text-align: center;
                color: #323232;
                margin-top: 0.28rem;
            }
        }
    }

    .qr-code-box {
        margin: 0 0.24rem;
    }

    .result-button {
        font-size: 0.26rem;
        display: flex;
        justify-content: center;
        margin-top: 0.8rem;

        p {
            width: 2.38rem;
            height: 0.86rem;
            border: 1px solid #dcdcdc;
            text-align: center;
            line-height: 0.86rem;
            color: #8b8b8b;
        }

        .red {
            color: #e30011;
            border-color: #e30011;
            margin-right: 0.4rem;
        }
    }
}
</style>
